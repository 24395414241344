/**
 * Convert the sort object to a string
 *
 * @param {Object} sort
 * @returns {String}
 */
export default function sortConverter(sort) {
  // If it's about followers
  if (sort.field === "followers") {
    if (sort.direction === "desc") {
      return "highest_followers"
    }
    else {
      return "lowest_followers"
    }
  }

  // Fallback to field
  return sort.field
}
