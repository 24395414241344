<template>
  <div class="influencer-discovery-results mt-8">
    <v-divider></v-divider>

    <!-- Show a brief of all data that there is -->
    <div class="d-flex flex-wrap justify-space-between align-center mb-6 mb-lg-0">
      <div class="text-h6 font-weight-bold mb-4 mt-6">{{ results.total.toLocaleString() }} results found</div>

      <component
        :is="isDesktopDevice ? 'div' : 'v-slide-group'"
      >
        <!-- To add to a group -->
        <v-btn
          v-if="isInfluencerGroupsAllowed && selectedItems.length > 1"
          depressed
          class="mr-3"
          color="primary"
          @click="showInfluencerGroupsFormDialog()"
        >
          <v-icon left> add </v-icon>

          Group
        </v-btn>

        <!-- Show a menu if the action is allowed for this user to enter rows count in export -->
        <v-menu
          v-model="shouldShowSaveSearchDialog"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{}">
            <v-btn
              depressed
              class="mr-3"
              color="primary"
              @click="handleSaveClick"
              :disabled="isSavingSearch || !areLastSearchFiltersAvailable"
              :loading="isSavingSearch"
              :class="{ 'shadow--primary': !isSavingSearch && areLastSearchFiltersAvailable }"
            >
              {{ isSearchSaved ? "Search Saved" : "Save Search" }}
              <v-icon right>
                {{ isSearchSaved ? "bookmark" : "bookmark_add" }}
              </v-icon>
            </v-btn>
          </template>
          <v-card width="360">
            <v-card-title class="d-flex justify-space-between primary white--text pb-4">
              <span>
                {{ isSearchSaved ? "Edit this search" : "Save this search" }}
              </span>

              <v-btn
                v-if="isSearchSaved"
                depressed
                color="primary"
                :disabled="isSavingSearch"
                :loading="isSavingSearch"
                @click="copySavedSearchLink"
              >
                <v-icon left>link</v-icon>

                Copy URL
              </v-btn>
            </v-card-title>

            <v-card-subtitle class="pt-6">
              {{ isSearchSaved ? "Edit this saved search" : "Save this search to access it later" }}
              {{ isWhitelisted ? ", as well as share them with your team" : "" }}
            </v-card-subtitle>

            <v-card-text :class="{ 'black--text': isThemeLight }">
              <v-text-field
                v-model="saveSearchForm.title"
                label="Search Name"
                placeholder="Optional"
                @input="$v.saveSearchForm.title.$touch()"
                @blur="$v.saveSearchForm.title.$touch()"
                :hide-details="!$v.saveSearchForm.title.$anyError"
                :error-messages="$v.saveSearchForm.title.$anyError ? ['Please enter a shorter name'] : null"
                :disabled="isSavingSearch"
                class="mb-3"
                outlined
                dense
              ></v-text-field>

              <v-textarea
                v-model="saveSearchForm.notes"
                label="Optional notes"
                @input="$v.saveSearchForm.notes.$touch()"
                @blur="$v.saveSearchForm.notes.$touch()"
                :hide-details="!$v.saveSearchForm.notes.$anyError"
                :error-messages="$v.saveSearchForm.notes.$anyError ? ['Please enter a note under 500 characters'] : null"
                :disabled="isSavingSearch"
                :counter="500"
                class="mb-3"
                outlined
                dense
              ></v-textarea>

              <v-select
                v-model="saveSearchForm.sharingAccess"
                label="Sharing Access"
                :items="sharingAccessOptions"
                :disabled="isSavingSearch"
                outlined
                dense
              ></v-select>
            </v-card-text>

            <v-card-actions class="px-4 pb-4">
              <v-btn
                v-if="isSearchSaved"
                text
                color="primary"
                :disabled="isSavingSearch"
                @click="handleSaveSearchDelete"
              >
                Delete
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                depressed
                color="primary"
                :disabled="isSavingSearch"
                :loading="isSavingSearch"
                @click="handleSaveSearchSubmit"
              >
                {{ isSearchSaved ? "Update" : "Save" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <!-- Show a menu if the action is allowed for this user to enter rows count in export -->
        <v-menu v-model="shouldShowExportDialog" :close-on-content-click="false">
          <template v-slot:activator="{}">
            <v-btn
              depressed
              color="primary"
              @click="handleDownloadClick"
              :disabled="isRequestingExport"
              :loading="isRequestingExport"
              :class="{ 'shadow--primary': !isRequestingExport }"
            >
              Export Result
              <v-icon right>download</v-icon>
            </v-btn>
          </template>
          <v-card width="360">
            <v-card-title class="primary white--text"> {{ results.total.toLocaleString() }} influencers found </v-card-title>

            <v-card-subtitle v-if="Number(exportLimit) > 0" class="pt-6">
              It'll cost you
              {{ (Number(exportLimit) * constants.model.unlockedAccount.cost.influencerDiscovery) / constants.conversionRate }}
              credits
            </v-card-subtitle>

            <v-card-text :class="{ 'black--text': isThemeLight }">
              <v-text-field
                v-model="exportTitle"
                label="Export Filename"
                placeholder="Optional"
                @input="$v.exportTitle.$touch()"
                @blur="$v.exportTitle.$touch()"
                :hide-details="!$v.exportTitle.$anyError"
                :error-messages="$v.exportTitle.$anyError ? ['Please enter a shorter filename'] : null"
                :disabled="isRequestingExport"
                class="mb-3"
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="exportLimit"
                type="number"
                label="Amount of influencers needed"
                @input="$v.exportLimit.$touch()"
                @blur="$v.exportLimit.$touch()"
                :hide-details="!$v.exportLimit.$anyError"
                :error-messages="exportLimitErrors"
                :disabled="isRequestingExport || selectedItems.length > 0"
                outlined
                dense
              ></v-text-field>

              <div class="mt-3">
                <v-checkbox
                  v-if="isAdmin"
                  v-model="shouldExcludeContactDetails"
                  label="Exclude Contact Details"
                  :disabled="isRequestingExport || platform === 'linkedin'"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="shouldExcludePreviouslyExported"
                  label="Exclude Previously Exported"
                  :disabled="isRequestingExport || selectedItems.length > 0"
                  hide-details
                ></v-checkbox>
              </div>
            </v-card-text>

            <v-card-actions class="px-4 pb-4">
              <v-spacer></v-spacer>

              <v-btn
                depressed
                color="primary"
                :disabled="isRequestingExport || $v.exportLimit.$anyError"
                :loading="isRequestingExport"
                @click="handleExportSubmit"
              >
                Export {{ Number(exportLimit) > 0 ? Number(exportLimit) : "" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </component>
    </div>

    <!-- Data Table -->
    <v-data-table
      :items="results.accounts"
      hide-default-footer
      disable-pagination
      class="elevation-1 custom-table--influencer-search"
      mobile-breakpoint="100"
    >
      <template v-slot:header>
        <thead class="v-data-table-header white-space-nowrap">
          <tr>
            <!-- sort by filter -->
            <th colspan="2">
              <div class="d-flex align-center pl-9">
                Sort By: &nbsp;

                <div class="contain-select-width">
                  <v-select
                    v-model="sortBy"
                    :items="sortOptions"
                    hide-details
                    dense
                    dark
                    solo
                    flat
                    full-width
                    background-color="secondary"
                    :append-icon="null"
                    @change="updateSortBy"
                  ></v-select>
                </div>
              </div>
            </th>

            <th>Followers</th>

            <template v-if="['tiktok', 'youtube'].includes(platform)">
              <th>Views</th>
            </template>

            <template v-if="['instagram', 'tiktok', 'youtube'].includes(platform)">
              <th>Engagement Rate</th>
            </template>

            <template v-if="doesReelsPlayFilterExist">
              <th>Avg Reel Views</th>
            </template>

            <th></th>
          </tr>
        </thead>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="item.user_id" :class="{ 'selected-row': selectedRows[item.user_id] }">
            <td class="pr-0">
              <div class="d-flex align-center justify-space-between">
                <span>
                  {{ index + 1 }}
                </span>

                <v-simple-checkbox color="primary" :disabled="item.is_locked" v-model="selectedRows[item.user_id]"></v-simple-checkbox>
              </div>
            </td>

            <td class="text-no-wrap py-3 pl-2">
              <profile-chip
                :data="item"
                :platform="platform"
                :reverse-name-and-username="platform === 'linkedin'"
                :is-blurred="item.is_locked"
              />
            </td>

            <td>
              {{ nFormatter(item.followers) }}
            </td>

            <template v-if="['tiktok', 'youtube'].includes(platform)">
              <td>
                {{ nFormatter(item.avg_views) }}
              </td>
            </template>

            <template v-if="['instagram', 'tiktok', 'youtube'].includes(platform)">
              <td>
                {{ (item.engagement_rate * 100).toFixed(2) }}
              </td>
            </template>

            <template v-if="doesReelsPlayFilterExist">
              <td>
                {{ nFormatter(item.avg_reels_plays) }}
              </td>
            </template>

            <td class="text-right">
              <div class="d-flex justify-end">
                <v-btn
                  v-if="['instagram', 'tiktok', 'youtube'].includes(platform)"
                  small
                  depressed
                  class="py-4"
                  color="primary"
                  @click="showInsights(item)"
                >
                  <v-icon left>insights</v-icon>

                  Insights
                </v-btn>

                <v-btn
                  v-if="isContentDiscoveryAllowed && ['instagram', 'tiktok'].includes(platform)"
                  small
                  depressed
                  class="py-4 ml-3"
                  color="primary"
                  @click="showContentDiscoveryFormDialog(item)"
                >
                  <v-icon left>collections</v-icon>

                  Search
                </v-btn>

                <!-- Show menu options -->
                <v-menu
                  v-if="isInfluencerGroupsAllowed || isCompetitorCheckAllowed"
                  transition="slide-y-transition"
                  :close-on-content-click="true"
                  offset-y
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="ml-3"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <!-- Show the menu options -->
                  <v-list width="170" dense>
                    <v-list-item
                      v-if="isInfluencerGroupsAllowed"
                      @click="showInfluencerGroupsFormDialog(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          Add To Group
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="isCompetitorCheckAllowed && ['instagram', 'tiktok'].includes(platform)"
                      @click="showCompetitorCheckFormDialog(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          Competitor Check
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <!-- To show the redirect dialog -->
    <v-dialog v-model="shouldShowInsightsRedirectDialog" max-width="400">
      <v-card>
        <!-- Show the image here -->
        <div class="mx-auto" style="max-width: 360px">
          <lottie-animation loop file="70522-data-inform-illustration-animation.json" />
        </div>

        <v-card-title> Unlock insights? </v-card-title>

        <v-card-text>
          If you haven't unlocked a report for this influencer before, by clicking "continue" you'd be charged 1 token for it.
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text class="mr-3" color="primary" @click="shouldShowInsightsRedirectDialog = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="redirectToInsights"> Continue </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <competitor-check-form
      v-model="shouldShowCompetitorCheckForm"
      :influencer="selectedInfluencer"
      source="discovery"
    />

    <content-discovery-form
      v-model="shouldShowContentDiscoveryForm"
      :influencer="selectedInfluencer"
      :input="computedKeywordValue"
      source="discovery"
      @created="refreshContentDiscoveryResults"
    />

    <influencer-groups-search
      v-model="shouldShowInfluencerGroupsForm"
      :influencers="computedSelectedItems"
    />
  </div>
</template>

<script>
// Import vuetify component
import { VSlideGroup } from 'vuetify/lib'
// @BUGFIX https://github.com/vuetifyjs/vuetify/issues/12224#issuecomment-894201049
import Ripple from "vuetify/lib/directives/ripple"

// Import helper validator functions
import { required, minValue, maxValue, maxLength, integer } from "vuelidate/lib/validators"
import { getValue, getValueByKind } from "@/helpers/store.js"
import sortConverter from "@/helpers/sortConverter"

// Import child component
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")
const CompetitorCheckForm = () => import(/* webpackChunkName: "competitor-check-form-component" */ "@/components/competitor-check/Form.vue")
const ContentDiscoveryForm = () => import(/* webpackChunkName: "content-discovery-form-component" */ "@/components/content-discovery/Form.vue")
const InfluencerGroupsSearch = () => import(/* webpackChunkName: "influencer-groups-search" */ "@/components/crm/influencer-groups/Search.vue")

// Export the SFC
export default {
  // Name of this SFC component
  name: "ResultsTable",

  // Register the directive objects
  directives: {
    Ripple
  },

  // Register the children components
  components: {
    VSlideGroup,

    ProfileChip,
    LottieAnimation,
    CompetitorCheckForm,
    ContentDiscoveryForm,
    InfluencerGroupsSearch,
  },

  // Define the incoming props
  props: {
    // The social media platform
    platform: {
      type: String,
      required: true
    },

    // The API result to be shown in tabular form
    results: {
      type: Object,
      required: true
    },

    // To disable the export button if it's being done
    isRequestingExport: {
      type: Boolean,
      required: true
    },

    // The search filters used in last search query
    lastSearchRequest: {
      type: Object,
      required: true
    }
  },

  // Define the Options API data variables
  data: () => ({
    // Used in the table view
    sortBy: "highest_followers",

    // An object with key value pair, key is the user ID and value is a boolean
    selectedRows: {},

    // Whether or not to show the export dialog
    shouldShowExportDialog: false,

    // Whether or not to include contact details
    shouldExcludeContactDetails: false,
    // Whether or not to keep the accounts previously exported
    shouldExcludePreviouslyExported: false,

    // The final filename to be used
    exportTitle: "",
    // The number of the rows to export
    exportLimit: 10,

    // Should show the save search dialog
    isSavingSearch: false,
    saveSearchResponse: null,
    shouldShowSaveSearchDialog: false,
    didDeleteSavedSearch: false,
    saveSearchForm: {
      title: "",
      notes: "",
      sharingAccess: "self"
    },

    // Whether or not to show the insights dialog
    shouldShowInsightsRedirectDialog: false,

    // For individual + bulk actions
    selectedInfluencer: null,
    shouldShowCompetitorCheckForm: false,
    shouldShowContentDiscoveryForm: false,
    shouldShowInfluencerGroupsForm: false,
  }),

  // Define the computed properties
  computed: {
    /**
     * Get the logged in user's data object
     *
     * @returns {Object}
     */
    profileData() {
      return this.$store.getters["auth/profile"]
    },

    /**
     * Whether or not the logged in user is an admin
     *
     * @returns {Boolean}
     */
    isAdmin() {
      return this.$store.getters["auth/isAdmin"]
    },

    /**
     * Whether or not the currently authenticated user is whitelisted or not
     *
     * @returns {Boolean}
     */
    isWhitelisted() {
      return this.$store.getters["auth/isWhitelisted"]
    },

    /**
     * Only show the column for reels plays if this filter is applied
     *
     * @return {Boolean}
     */
    doesReelsPlayFilterExist() {
      return Boolean(this.$store.getters["influencerDiscovery/findFilterByType"]("reelViews"))
    },

    /**
     * Map the selectedRows with their user data
     *
     * @return {Array}
     */
    selectedItems() {
      // For each selected row
      return (
        Object.entries(this.selectedRows)
          // filter out rows with values "true"
          .filter((item) => item[1])
          // for each of them, compare with the key
          .map((item) => this.results.accounts.find((search) => search.user_id === item[0]))
          // for each of them, make sure to fix the engagement rate value
          .map((item) => ({
            ...item,
            engagement_rate: (item.engagement_rate * 100).toFixed(2)
          }))
      )
    },

    /**
     * Get the sort options for the filters
     *
     * @return {Array}
     */
    sortOptions() {
      return [
        {
          text: "Relevance",
          value: "audience_relevance",
          condition: ["instagram", "tiktok", "youtube", "linkedin"].includes(this.platform)
        },
        {
          text: "Followers (Highest)",
          value: "highest_followers"
        },
        {
          text: "Followers (Lowest)",
          value: "lowest_followers"
        },
        {
          text: "Engagements",
          value: "engagement_rate",
          condition: ["instagram", "tiktok", "youtube"].includes(this.platform)
        }
      ].filter((item) => (typeof item.condition === "boolean" ? item.condition : true))
    },

    /**
     * Get the export limit for this user
     *
     * @return {Number}
     */
    maximumExportLimit() {
      return Number(this.$store.getters["auth/getMetaValue"](constants.model.user.metas.discoveryExportsLimit))
    },

    /**
     * Vuelidate validation messages for the export limit input
     *
     * @return {Array}
     */
    exportLimitErrors() {
      // initiate the array of error messages
      const errors = []

      // if there has been no activity, show no error
      if (!this.$v.exportLimit.$dirty) return errors

      // else test it
      !this.$v.exportLimit.integer && errors.push("Please enter a number")
      !this.$v.exportLimit.required && errors.push("Please enter a value")
      !this.$v.exportLimit.minValue && errors.push("Please enter a number greater than 1")
      !this.$v.exportLimit.maxValue &&
        errors.push(
          `Please enter a number less than ${
            this.results.total > this.maximumExportLimit ? this.maximumExportLimit : this.results.total.toLocaleString()
          }`
        )

      // return the error messages
      return errors
    },

    /**
     * Whether or not to show the save search button
     *
     * @return {Boolean}
     */
    areLastSearchFiltersAvailable() {
      return Object.keys(this.lastSearchRequest.filter).length > 0
    },

    /**
     * Get the saved search response model
     *
     * @return {Object}
     */
    savedSearch() {
      // If we deleted the saved search,
      if (this.didDeleteSavedSearch) {
        return null
      }

      // Otherwise
      return this.saveSearchResponse || this.results?.savedSearch || null
    },

    /**
     * Whether or not the search is saved
     *
     * @return {Boolean}
     */
    isSearchSaved() {
      return this.savedSearch !== null
    },

    /**
     * Whether or not to show the influencer groups form
     *
     * @return {Boolean}
     */
    isInfluencerGroupsAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.influencerGroups)
    },

    /**
     * Whether or not to show the content discovery form
     *
     * @return {Boolean}
     */
    isContentDiscoveryAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.actionContentDiscovery)
    },

    /**
     * Whether or not to show the competitor check form
     *
     * @return {Boolean}
     */
    isCompetitorCheckAllowed() {
      return this.$store.getters['auth/isServiceAllowed'](constants.model.user.allowedServices.competitorCheck)
    },

    /**
     * Compute the selected items
     *
     * @return {Array}
     */
    computedSelectedItems() {
      // If an individual is selected
      if (this.selectedInfluencer) {
        return [this.selectedInfluencer]
      }

      // Otherwise
      return this.selectedItems
    },

    /**
     * Compute the intelligent keyword if such a filter is applied
     *
     * @return {String}
     */
    computedKeywordValue() {
      // If the last search request has no filter
      if (this.areLastSearchFiltersAvailable) {
        console.log("TO BE STOPPED HERE")
      }

      // If "intel search" filter is applied
      const filterKeywords = this.$store.getters["influencerDiscovery/findFilterByType"]("keywords")

      // If the filter exists
      if (filterKeywords && filterKeywords.data.inputs.shouldExclude === false) {
        return filterKeywords.data.inputs.value
      }

      // If "text tags" filter is applied
      const textTagFilters = this.$store.getters["influencerDiscovery/findFiltersByType"]("textTags")

      // Loop through them
      for (const item of textTagFilters) {
        // If the action is set to "should"
        if (item.data.inputs.action === "should") {
          return item.data.inputs.value
        }
      }

      // If "influencer interest" filter is applied
      const interestFilters = this.$store.getters["influencerDiscovery/findFiltersByTypeAndKind"]("interest", "influencer")

      // If it exists
      if (interestFilters.length > 0) {
        // Return the first value
        return interestFilters[0].data.inputs.name
      }

      // Fallback to empty string
      return ""
    }
  },

  // Define the Vuelidate validation rules
  validations() {
    return {
      exportTitle: {
        maxLength: maxLength(100)
      },

      exportLimit: {
        required,
        integer,
        minValue: minValue(1),
        maxValue: maxValue(this.results.total > this.maximumExportLimit ? this.maximumExportLimit : this.results.total)
      },

      saveSearchForm: {
        title: {
          maxLength: maxLength(100)
        },
        notes: {
          maxLength: maxLength(500)
        }
      },
    }
  },

  // Define local method functions
  methods: {
    /**
     * Redirect to the analytics page with Vue Router
     *
     * @return {void}
     */
    redirectToInsights() {
      this.$router.push({
        name: "InfluencerInsightReport",
        params: {
          platform: this.platform,
          username: this.selectedInfluencer.username
        },
        query: {
          accountId: this.selectedInfluencer.user_id
        }
      })
    },

    /**
     * Show a popup asking if we want to analyze or not
     *
     * @param {Object} item
     * @return {void}
     */
    showInsights(item) {
      this.selectedInfluencer = item
      this.shouldShowInsightsRedirectDialog = true
    },

    /**
     * Trigger network request to fetch with different sort
     *
     * @return {void}
     */
    updateSortBy() {
      // set the current sortBy attribute
      this.$store.dispatch("influencerDiscovery/updateSortBy", this.sortBy)
    },

    /**
     * Compute whether to show the dialog to enter custom input values or download the exported CSV/XLSX
     *
     * @return {void}
     */
    handleDownloadClick() {
      this.exportLimit = this.selectedItems.length || 10
      this.shouldShowExportDialog = true
    },

    /**
     * Handle the submit event to
     *
     * @return {void}
     */
    async handleExportSubmit() {
      await this.$v.exportLimit.$touch()

      // If there's some validation error
      if (this.$v.exportLimit.$anyError || this.$v.exportTitle.$anyError) {
        return
      }

      // Otherwise make the request
      this.$emit("export", {
        exportLimit: this.exportLimit,
        exportTitle: this.exportTitle,
        selectedRows: Object.keys(this.selectedRows),
        shouldExcludeContactDetails: this.shouldExcludeContactDetails,
        shouldExcludePreviouslyExported: this.shouldExcludePreviouslyExported
      })
    },

    /**
     * Handle the save click event
     *
     * @return {void}
     */
    handleSaveClick() {
      // Show the dialog
      this.shouldShowSaveSearchDialog = true
    },

    /**
     * Copy the saved search link
     *
     * @return {void}
     */
    copySavedSearchLink() {
      // Copy the link value
      const route = this.$router.resolve({ name: "SavedInfluencerDiscoverySearch", params: { uuid: this.savedSearch.uuid } })
      navigator.clipboard.writeText(`${window.location.origin}${route.href}`)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })

      // Hide the dialog
      this.shouldShowSaveSearchDialog = false
    },

    /**
     * Handle the save search create event
     *
     * @return {void}
     */
    async handleSavedSearchCreate() {
      // Show the loader
      const loaderId = Symbol("savedSearches/influencerDiscovery/create")
      this.$store.dispatch("loaders/add", loaderId)
      this.isSavingSearch = true

      try {
        // Make the network request
        const response = await axios({
          method: "POST",
          url: "/api/influencer-discovery-searches/",
          data: {
            platform: this.platform,

            title: this.saveSearchForm.title,
            notes: this.saveSearchForm.notes,
            sharing_access: this.saveSearchForm.sharingAccess,

            audience_source: this.lastSearchRequest.audience_source,
            filters: this.lastSearchRequest.filter,
            sort: this.lastSearchRequest.sort,

            count: this.results.total
          }
        })

        // Update the response model
        this.saveSearchResponse = response.data

        // Refresh the saved searches
        this.$store.dispatch("savedSearches/influencerDiscovery/fetchItems")

        // Hide the dialog
        this.shouldShowSaveSearchDialog = false
        this.didDeleteSavedSearch = false
      } catch (error) {
        // log using the helper function
        logger({ type: "Network Error", error, comment: "Error while creating saved influencer discovery search" })
      } finally {
        // close the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isSavingSearch = false
      }
    },

    /**
     * Handle the save search update event
     *
     * @return {void}
     */
    async handleSavedSearchUpdate() {
      // Show the loader
      this.isSavingSearch = true

      // Use store action
      await this.$store.dispatch("savedSearches/influencerDiscovery/updateItem", {
        id: this.savedSearch.id,
        title: this.saveSearchForm.title,
        notes: this.saveSearchForm.notes,
        sharingAccess: this.saveSearchForm.sharingAccess
      })

      // Hide the dialog
      this.isSavingSearch = false
      this.shouldShowSaveSearchDialog = false
      this.didDeleteSavedSearch = false
    },

    /**
     * Handle the save submit event
     *
     * @return {void}
     */
    async handleSaveSearchSubmit() {
      // Run validations
      await this.$v.saveSearchForm.$touch()

      // If there's some validation error
      if (this.$v.saveSearchForm.$anyError) {
        return
      }

      // If there's already a saved search, update it
      if (this.isSearchSaved) {
        this.handleSavedSearchUpdate()
      }
      // Otherwise
      else {
        this.handleSavedSearchCreate()
      }
    },

    /**
     * Handle the save search delete event
     *
     * @return {void}
     */
    async handleSaveSearchDelete() {
      // Show the loader
      this.isSavingSearch = true

      // Use store action
      await this.$store.dispatch("savedSearches/influencerDiscovery/deleteItem", this.savedSearch)

      // Hide the dialog
      this.isSavingSearch = false
      this.shouldShowSaveSearchDialog = false

      // Clear the saved search response
      this.saveSearchResponse = null

      // Show the deleted message
      this.didDeleteSavedSearch = true
    },

    /**
     * Show the content discovery form dialog
     *
     * @param {Object} item
     * @returns {void}
     */
    showContentDiscoveryFormDialog(item) {
      this.selectedInfluencer = item

      // Show the dialog
      this.shouldShowContentDiscoveryForm = true
    },

    /**
     * Show the competitor check form dialog
     *
     * @param {Object|Null} item
     * @returns {void}
     */
    showCompetitorCheckFormDialog(item = null) {
      this.selectedInfluencer = item

      // Show the dialog
      this.shouldShowCompetitorCheckForm = true
    },

    /**
     * Show the influencer group form dialog
     *
     * @param {Object|Null} item
     * @returns {void}
     */
    showInfluencerGroupsFormDialog(item = null) {
      this.selectedInfluencer = item

      // Show the dialog
      this.shouldShowInfluencerGroupsForm = true
    },

    /**
     * Refresh the content discovery results
     *
     * @returns {void}
     */
    refreshContentDiscoveryResults() {
      this.$store.dispatch("savedSearches/contentDiscovery/fetchItems")
    }
  },

  /**
   * As soon as the component is mounted
   *
   * @return {void}
   */
  mounted() {
    // If the search was previously saved
    if (this.isSearchSaved) {
      this.saveSearchForm.title = this.savedSearch.title
      this.saveSearchForm.notes = this.savedSearch.notes
      this.saveSearchForm.sharingAccess = this.savedSearch.sharing_access
    }

    // If there's any sort in getters
    if (this.$store.getters["influencerDiscovery/sort"]) {
      // Update the sortBy
      this.sortBy = sortConverter(this.$store.getters["influencerDiscovery/sort"])
    }
  }
}
</script>

<style lang="stylus">
.influencer-discovery-results
  .contain-select-width
    width 14em

    .v-select
      font-size 1.1em

  .selected-row
    background-color rgba(0, 0, 0, 0.1)
</style>
